import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VDialog, {
    attrs: {
      "scrollable": "",
      "max-width": "700px",
      "persistent": ""
    },
    model: {
      value: _vm.taskAssigned,
      callback: function ($$v) {
        _vm.taskAssigned = $$v;
      },
      expression: "taskAssigned"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "mx-0 px-0 my-0 py-0"
  }, [_c(VToolbar, {
    staticClass: "primary--text border-bottom-primary-4",
    attrs: {
      "top": "",
      "flat": ""
    }
  }, [_c(VToolbarTitle, {
    staticClass: "flex text-center"
  }, [_vm._v("Bearbeiter")])], 1)], 1), _c(VCardText, [_c('div', [_c(VRow, {
    staticClass: "mt-2",
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-overline font-weight-bold"
  }, [_vm._v("Benutzer")]), _c(VDivider)], 1), _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c(VAutocomplete, {
    staticClass: "mx-2",
    attrs: {
      "auto-select-first": "",
      "search-input": _vm.userSearchInput,
      "items": _vm.assignableUsers,
      "return-object": "",
      "item-text": item => `${item.userId}, ${item.username}`,
      "placeholder": "Hinzufügende Benutzer",
      "dense": "",
      "outlined": "",
      "multiple": "",
      "small-chips": "",
      "deletable-chips": ""
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.userSearchInput = $event;
      },
      "update:search-input": function ($event) {
        _vm.userSearchInput = $event;
      },
      "change": function ($event) {
        _vm.userSearchInput = '';
      }
    },
    model: {
      value: _vm.users,
      callback: function ($$v) {
        _vm.users = $$v;
      },
      expression: "users"
    }
  })], 1)], 1), _c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-overline font-weight-bold"
  }, [_vm._v("Gruppen")]), _c(VDivider)], 1), _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c(VAutocomplete, {
    staticClass: "mx-2",
    attrs: {
      "items": _vm.assignableWorkGroups,
      "return-object": "",
      "item-text": item => item.lfdnr + ' - ' + item.name,
      "item-value": "lfdnr",
      "placeholder": "Hinzufügende Gruppen",
      "auto-select-first": "",
      "search-input": _vm.groupSearchInput,
      "dense": "",
      "outlined": "",
      "multiple": "",
      "small-chips": "",
      "deletable-chips": ""
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.groupSearchInput = $event;
      },
      "update:search-input": function ($event) {
        _vm.groupSearchInput = $event;
      },
      "change": function ($event) {
        _vm.groupSearchInput = '';
      }
    },
    model: {
      value: _vm.groups,
      callback: function ($$v) {
        _vm.groups = $$v;
      },
      expression: "groups"
    }
  })], 1)], 1)], 1)]), _c(VCardActions, [_c(VBtn, {
    attrs: {
      "color": "error",
      "outlined": "",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        _vm.taskAssigned = false;
      }
    }
  }, [_vm._v("Abbrechen")]), _c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "primary",
      "outlined": "",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        return _vm.updateAssignees();
      }
    }
  }, [_vm._v(" Speichern ")])], 1)], 1), _c('TaskAssignedGroup', {
    ref: "group"
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };